import React , {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SteffenMImage from '../../images/steffenM.png';
import ErinCImage from '../../images/erinC.png';
import GregFImage from '../../images/gregF.png';
import { Navigation, Pagination } from 'swiper';
import SteffenVector from '../../../static/assets/Vectorsteffen.svg';
import SteffenNext from '../../../static/assets/nextIconSteffen.svg';
import ErinVector from '../../../static/assets/ErinVector.svg';
import ErinNext from '../../../static/assets/erinNext.svg';
import GregVector from '../../../static/assets/GregVector.svg';
import GregNext from '../../../static/assets/GregNext.svg';
const ChangeMakerTestiomonialComponent = props => {
  const [isActive, setIsActive] = useState(0);
  const { sliderItem, testimonialTitle, isLanding } = props;

  const reviwesSlide = {
    0: {
      Text: (
        <div className="testimonial-section-swiper">
          <button
            aria-label="testimonial"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive + 2)}
          >
            <SteffenVector />
          </button>
          <div className="testimonial-section-imageCol">
            <img
              alt="testimonial"
              src={SteffenMImage}
              className="testimonial-section-image"
            />
          </div>
          <div className=" testimonial-section-text">
            <p className="testimonial-section-name">Steffen M.</p>
            <p className="testimonial-section-position">Consultant</p>
            <p className="testimonial-section-description">
              “The only people that can improve a company are the employees. And
              they want to! Agreed makes it possible for them to do so.”
            </p>
          </div>
          <button
            aria-label="next"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive + 1)}
          >
            <SteffenNext />
          </button>
        </div>
      ),
    },
    1: {
      Text: (
        <div className="testimonial-section-swiper">
          <button
            aria-label="erin"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive - 1)}
          >
            <ErinVector />
          </button>
          <div className=" testimonial-section-imageCol">
            <img
              src={ErinCImage}
              className="testimonial-section-image"
              alt="testmonail"
            />
          </div>
          <div className=" testimonial-section-text">
            <p className="testimonial-section-name">Erin C.</p>
            <p className="testimonial-section-position">
              Managing Director, Deloitte Consulting
            </p>
            <p className="testimonial-section-description">
              “I see Agreed as a critical enabler for workforce transformation.”
            </p>
          </div>
          <button
            aria-label="erin next"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive + 1)}
          >
            <ErinNext />
          </button>
        </div>
      ),
    },
    2: {
      Text: (
        <div className="testimonial-section-swiper">
          <button
            aria-label="greg"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive - 1)}
          >
            <GregVector />
          </button>
          <div className=" testimonial-section-imageCol">
            <img
              src={GregFImage}
              className="testimonial-section-image"
              alt="testmonail"
            />
          </div>
          <div className=" testimonial-section-text">
            <p className="testimonial-section-name">Greg F.</p>
            <p className="testimonial-section-position">Finance Director</p>
            <p className="testimonial-section-description">
              “We used the Agreed process to increase earnings by $100m+.”
            </p>
          </div>
          <button
            aria-label="greg next"
            className="testimonial-section-swiper-button"
            onClick={() => setIsActive(isActive - 2)}
          >
            <GregNext />
          </button>
        </div>
      ),
    },
  };
  return (
    <div className="testimonial-section">
      <div className="full-container">
        <h4
          className={`testimonial-section-title ${
            isLanding ? 'testimonial-section-title-landing' : ''
          }`}
        >
          {testimonialTitle}
        </h4>
        {!isLanding && (
          <div className="testimonial-section-desktop">
            {reviwesSlide[isActive].Text}
            <div className="testimonial-section-pagination">
              <ul className="testimonial-section-pagination-list">
                {sliderItem &&
                  sliderItem.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="steps-section-pagination-list-item"
                      >
                        <button
                          aria-label="button"
                          className={`steps-section-pagination-item ${
                            index === isActive
                              ? 'steps-section-pagination-item-active'
                              : ''
                          }`}
                          onClick={() => {
                            setIsActive(index);
                          }}
                        ></button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
      </div>
        {!isLanding && (
      <div className="testimonial-section-mobile">
        <Swiper
          navigation={true}
          slidesPerView={'1'}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
        >
          <SwiperSlide className="testimonial-section-prov">
            <div className="testimonial-section-swiper">
              <div className="testimonial-section-imageCol">
                <img
                  alt="testmonail"
                  src={SteffenMImage}
                  className="testimonial-section-image"
                />
              </div>
              <div className=" testimonial-section-text">
                <p className="testimonial-section-name">Steffen M.</p>
                <p className="testimonial-section-position">Consultant</p>
                <p className="testimonial-section-description">
                  “The only people that can improve a company are the employees.
                  And they want to! Agreed makes it possible for them to do so.”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-section-swiper">
              <div className=" testimonial-section-imageCol">
                <img
                  src={ErinCImage}
                  className="testimonial-section-image"
                  alt="testmonail"
                />
              </div>
              <div className=" testimonial-section-text">
                <p className="testimonial-section-name">Erin C.</p>
                <p className="testimonial-section-position">
                  Managing Director, Deloitte Consulting
                </p>
                <p className="testimonial-section-description">
                  “I see Agreed as a critical enabler for workforce
                  transformation.”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-section-swiper">
              <div className=" testimonial-section-imageCol">
                <img
                  src={GregFImage}
                  className="testimonial-section-image"
                  alt="testmonail"
                />
              </div>
              <div className=" testimonial-section-text">
                <p className="testimonial-section-name">Greg F.</p>
                <p className="testimonial-section-position">Finance Director</p>
                <p className="testimonial-section-description">
                  “We used the Agreed process to increase earnings by $100m+.”
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
        )}
    </div>
  );
};
export default ChangeMakerTestiomonialComponent;