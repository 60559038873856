import React from "react";
import HeaderComponentLeader from "../components/landingComponents/headerComponentLeader";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import Header from "../components/headerComponent";
import AgreedHelpComponent from '../components/homeCompnent/agreedHelpComponent';
import ChangeMakerTestiomonialComponent from '../components/landingComponents/changeMakerTestimonialComponent.js';
import NewFooterComponent from '../components/newFooterComponent';
import RequestADemoComponent from "../components/landingComponents/requestADemoComponent";
import BannerComponent from "../components/landingComponents/bannerComponent";
import DemoImage from '../images/requestADemo.png';

const DiversityLandingPage = (props) =>{
    const {
      data: {
        wpPost: {
          seo,
          landingPageLeadSection: {
            leadSubtitle,
            leadTitle,
            leadImage: {
              localFile: {
                childrenImageSharp: [{ gatsbyImageData: leadImage }],
              },
            },
          },
          agreedHelpSection: {
            agreedHelpSectionDescription,
            agreedHelpSectionSubtitle,
            agreedHelpSectionTitle,
            agreedHelpSectionImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: agreedHelpSectionImage },
              },
            },
          },
          agreedHelpSavingTimeSection: {
            savingTimeSectionDescription,
            savingTimeSectionTitle,
            savingTimeSectionImage: {
              localFile: {
                childImageSharp: { gatsbyImageData: savingTimeSectionImage },
              },
            },
          },
          beAmazedByWhatYourPeopleProduceSection: {
            beAmazedByWhatYourPeopleProduceSectionDescription,
            beAmazedByWhatYourPeopleProduceSectionTitle,
            beAmazedByWhatYourPeopleProduceSectionImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: beAmazedByWhatYourPeopleProduceSectionImage,
                },
              },
            },
          },
        },
      },
      pageContext: { title },
    } = props;
    return (
      <Layout seo={seo} title={title}>
        <Header />
        <HeaderComponentLeader
          teamworkTitle={leadTitle}
          teamworkDescription={leadSubtitle}
          isLandingPage={true}
          landingImage={leadImage}
          isLanding={true}
        />
        <BannerComponent isLandingPage={true}/>
        <AgreedHelpComponent
          helpTitle={agreedHelpSectionTitle}
          helpSubtitle={agreedHelpSectionSubtitle}
          helpDescription={agreedHelpSectionDescription}
          agreedScreenshots={agreedHelpSectionImage}
          isFirst={true}
          isSecond={false}
          isLanding={true}
          isLandingPage={true}
        />
        <AgreedHelpComponent
          helpSubtitle={savingTimeSectionTitle}
          helpDescription={savingTimeSectionDescription}
          agreedScreenshots={savingTimeSectionImage}
          isSecond={true}
          isLanding={true}
          isLandingPage={true}
        />
        <AgreedHelpComponent
          helpSubtitle={beAmazedByWhatYourPeopleProduceSectionTitle}
          helpDescription={beAmazedByWhatYourPeopleProduceSectionDescription}
          agreedScreenshots={beAmazedByWhatYourPeopleProduceSectionImage}
          isEnd={true}
          isSecond={false}
          isLanding={true}
          isLandingPage={true}
        />
        <ChangeMakerTestiomonialComponent
          // sliderItem={sliderItem}
          testimonialTitle="Agreed - a comprehensive approach to leading change through diversity, equity, and inclusion"
          isLanding={true}
        />
        <RequestADemoComponent
          isLanding={true}
          requestDemoTitle="Request a Demo"
          RequestADemoImage={DemoImage}
        />
        <NewFooterComponent isProduct={true} />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPost(slug: { eq: "diversity-equity-and-inclusion" }) {
      seo {
        metaDesc
        title
      }
      landingPageLeadSection {
        leadButton
        leadSubtitle
        leadTitle
        leadImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSection {
        agreedHelpSectionButtonText
        agreedHelpSectionDescription
        agreedHelpSectionSubtitle
        agreedHelpSectionTitle
        agreedHelpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSavingTimeSection {
        savingTimeSectionButtonText
        savingTimeSectionDescription
        savingTimeSectionTitle
        savingTimeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      beAmazedByWhatYourPeopleProduceSection {
        beAmazedByWhatYourPeopleProduceSectionButtonTitle
        beAmazedByWhatYourPeopleProduceSectionDescription
        beAmazedByWhatYourPeopleProduceSectionTitle
        beAmazedByWhatYourPeopleProduceSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default DiversityLandingPage